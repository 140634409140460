<template>
  <div
    class="popup-container align-items-center"
    v-show="blnPopupConfirmDelete == true && intWrongLogin < intPinCount"
  >
    <transition name="zoom">
      <AlertPopup
        v-show="blnPopupConfirmDelete == true && intWrongLogin < intPinCount"
        type="Danger"
        classOkBtn="btn-danger"
        artIcon="deleteFill"
        :altMsgBox="$t('186', { fieldname: ' ' + $t('8').toLowerCase() })"
        :Helper="$t('345')"
        @confirm="fetchDeleteEduLevelList($event)"
        :blnLoading="blnloadConfirmDelet"
        ref="AlertPopup"
        :showpass="true"
        :btnpop="true"
        classIcon="fill-danger"
        :errorMsg="$t(errorMsg)"
        :blnError="blnError"
      />
    </transition>
    <transition name="fade">
      <div
        class="overlay"
        v-show="blnPopupConfirmDelete == true && intWrongLogin < intPinCount"
        @click="blnloadConfirmDelet == false ? ConfirmDelete() : ''"
      ></div>
    </transition>
  </div>
  <div
    class="popup-container align-items-center"
    v-show="blnPopupConfirmDelete == true && intWrongLogin >= intPinCount"
  >
    <transition name="zoom">
      <AlertPopup
        v-show="blnPopupConfirmDelete == true && intWrongLogin >= intPinCount"
        type="Warning"
        classOkBtn="btn-secondary"
        artIcon="WarningOutline"
        :altMsgBox="$t('1287', { LONGINFAILNUM: intPinCount, LockContact: strTel })"
        :Helper="$t('345')"
        @confirm="reloadpage($event)"
        ref="AlertPopupp"
        :btnpop="true"
        classIcon="fill-warning"
        :Cancel="false"
      />
    </transition>
    <transition name="fade">
      <div
        class="overlay"
        v-show="blnPopupConfirmDelete == true && intWrongLogin >= intPinCount"
      ></div>
    </transition>
  </div>
  <AltStatus
    :blnalert="blnAltStatus"
    icon="CorrectFill"
    coloricon="fill-success"
    class="success"
    :name="$t(strSMSGsuccess)"
    :class="blnAltStatus ? 'show' : ''"
  />
  <AltStatus
    :blnalert="blnAltStatusunsucess"
    icon="IncorrectFill"
    coloricon="fill-danger"
    class="error"
    :name="$t('174')"
    @mousemove="hover = true"
    @mouseleave="hover = false"
    :class="blnAltStatusunsucess ? 'show' : ''"
  />
  <AltStatus
    :blnalert="blnAltLoadingData"
    icon="IncorrectFill"
    coloricon="fill-danger"
    class="error"
    :name="$t('223')"
    @mousemove="hover = true"
    @mouseleave="hover = false"
    :class="blnAltLoadingData ? 'show' : ''"
  />
  <AltStatus
    :blnalert="blnnotdelete"
    icon="IncorrectFill"
    coloricon="fill-danger"
    class="error"
    :name="$t('428')"
    @mousemove="hover = true"
    @mouseleave="hover = false"
    :class="blnnotdelete ? 'show' : ''"
  />
  <AltStatus
    :blnalert="blnnotedit"
    icon="IncorrectFill"
    coloricon="fill-danger"
    class="error"
    :name="$t('222')"
    @mousemove="hover = true"
    @mouseleave="hover = false"
    :class="blnnotedit ? 'show' : ''"
  />

  <Header
    icon="educationlevelOutline"
    :headerName="$t('8')"
    :btnName="$t('103')"
    :breadcrumbname="
      action == 'Insert' ? $t('103') : '' || action == 'Update' ? $t('398') : ''
    "
    class="mb-3"
    @onInput="openform($event)"
    :breadcrumb="blnOpenform == true ? '' : 'd-none'"
    :helperHeader="
      blnOpenform == true ? $t('200', { fieldname: $t('8').toLowerCase() }) : ''
    "
    :hintHelperHeader="blnOpenform == true ? '' : 'd-none'"
    :rightHeader="blnOpenform == true ? 'd-none' : ''"
    :underline="blnOpenform == true ? 'openUnderline' : 'closeUnderline'"
    @showFilter="openFilter($event)"
    id="header"
    ListEduLevelListClass="d-none"
    :Searchname="$t('102')"
    @onSearch="SearcEducation($event)"
    @onClean="clean()"
    :blnOpenform="blnOpenform"
    :showFilter="blnOpenFilter"
    :filterActive="{
      blnOpenform: blnOpenFilter,
      useFilter: activeFiltersSelected.length == 0 ? false : true,
      disableFilter: false,
    }"
    @ConfigDisplay="reloadConfigDisplay()"
    ref="header"
  />
  <div class="popup-container" v-show="blnOpenFilter == true">
    <transition name="zoom">
      <Filter
        v-show="blnOpenFilter == true"
        @appectFilter="filteredData($event)"
        :listDataFilter="listEducationFilter"
        @Cancel="blnOpenFilter = !blnOpenFilter"
        :listActiveFiltersSelected="activeFiltersSelected"
        :blnOpenFilter="blnOpenFilter"
        :blnHaveDecision="false"
        ref="filter"
      />
    </transition>
    <transition name="fade">
      <div
        class="overlay"
        v-show="blnOpenFilter == true"
        @click="blnOpenFilter = false"
      ></div>
    </transition>
  </div>
  <Table
    :progressScore="progress"
    :nametable="'8'"
    v-show="ListEducation.dataFields != null"
    v-if="!blnOpenform"
    :ListDataTable="ListEducation"
    firstSort="intEducationStatus"
    secondSort="strEducationNameEN"
    @edit="fetchGetEduLevelListbyId($event)"
    @delete="fetchDeleteEduLevelList($event)"
    @ConfigDisplay="reloadConfigDisplay()"
    @onSaveConfigDisplay="onSaveConfigDisplay($event)"
    ref="table"
  />

  <!-- @ConfigDisplay="reloadConfigDisplay()" -->
  <div
    class="d-flex mt-sm-3 ms-lg-9 ms-md-0 m-sm-0 justify-content-start align-items-start"
    v-if="blnOpenform == true"
    :class="
      device == 'mobile' || device == 'tablet'
        ? 'flex-wrap move-to-top-level'
        : ''
    "
  >
    <div
      class="rounded-2 shadow-sm bg-white me-lg-5 me-sm-0 layout"
      :class="device == 'mobile' ? 'mb-5 me-0 mb-0 w-100' : 'form-md mb-4'"
    >
      <div
        class="p-5 d-flex flex-column gap-5 justify-content-center"
        v-if="blnloading == true"
        style="height: 414px"
      >
        <div class="skeleton rounded" style="width: 100%; height: 30px">
          <div class="skeleton-item rounded mb-3"></div>
        </div>
        <div class="skeleton rounded" style="width: 100%; height: 30px">
          <div class="skeleton-item rounded mb-3"></div>
        </div>
        <div class="skeleton rounded" style="width: 100%; height: 30px">
          <div class="skeleton-item rounded mb-3"></div>
        </div>
        <div class="skeleton rounded" style="width: 20%; height: 50px">
          <div class="skeleton-item rounded mb-3"></div>
        </div>
        <div class="d-flex gap-3">
          <div class="skeleton rounded" style="width: 20%; height: 40px">
            <div class="skeleton-item rounded mb-3"></div>
          </div>
          <div class="skeleton rounded" style="width: 20%; height: 40px">
            <div class="skeleton-item rounded mb-3"></div>
          </div>
        </div>
      </div>
      <div v-show="blnloading == false">
        <Form
          v-if="blnOpenform == true"
          :typeSchema="typeSchema"
          :data="formdata"
          @onInput="openform($event)"
          @Insert="fetchInsertEducation($event)"
          @Update="fetchUpdateEduLevel($event)"
          @delete="ConfirmDelete()"
          :action="action"
          :request="request"
          :inactive="formInactive"
          :msgalertDataDuplicate="msgalertDataDuplicate"
          ref="form"
        />
      </div>
    </div>
    <Relate
      :class="device == 'mobile' || device == 'tablet' ? 'mt-3 mb-3 w-100' : ''"
      v-if="blnOpenform == true && listRelate.used == true"
      :listRelate="listRelate"
      :label="$t('418', { fieldname: $t('8').toLowerCase() })"
      style="width: 350px"
      class="layout"
    />
  </div>
</template>

<script>
import AlertPopup from "@/components/alert/alertPopup.vue";
import Relate from "@/components/cards/relate.vue";
import Filter from "@/components/filter/filter.vue";
import Header from "@/components/headerMenu/haeder.vue";
import Table from "@/components/table/table.vue";
import urlapi from "@/typescript/urlapi/url";
import apiedulevel from "@/typescript/urlapi/adminsystem/APIEduLevel";
import axios from "axios";
//import SortArray from "../typescript/sort/sorting";
// import EducationField from "../components/table/education.vue";
import Enumerable from "linq";
import Form from "@/components/form/form.vue";
import AltStatus from "@/components/alert/alertStatus.vue";
import { typeSchema, request } from "@/components/constants/schemaAddEduLevel";
import AdsInventories from "@/typescript/inventory";
import Login from "@/typescript/login";
import VueCookies from "vue-cookies";
import cookiesPath from "@/typescript/urlapi/cookiesPath";
export default {
  components: {
    Header,
    Table,
    Filter,
    Form,
    AltStatus,
    AlertPopup,
    Relate,

    // EducationField,
  },
  data() {
    return {
      blnloadError: false,
      blnloadConfirmDelet: false,
      blnloading: false,
      strSearch: "",
      intWrongLogin: 0,
      intPinCount: 1,
      listRelate: [],
      strTel: null,
      blnPopupConfirmDelete: false,
      timerCountHover: 0,
      msgalertDataDuplicate: [], //msg
      action: "Insert", //action of form
      strSMSGsuccess: "",
      blnAltStatus: false, //Alt Status Success
      blnAltStatusunsucess: false, //Alt Status unsuccess
      blnAltLoadingData: false, //Alt Status logint
      blnnotdelete: false,
      blnnotedit: false,
      hover: false, //hover alt
      typeSchema: typeSchema, //
      timerEnabled: false,
      request: request,
      formInactive: [],
      strSearchField: ["strEducationNameEN", "strEducationNameTH"],
      timerCount: 0,
      progress: 0,
      educationID: 0,
      formdatadummy: {},
      formdata: {},

      updateAdsInventories: null,
      blnOpenform: false,
      activeFiltersSelected: [],
      ListEducation: {},
      ListDummy: {},
      eduLevelID: null,
      blnOpenFilter: false,
      listEducationFilter: [
        {
          list: [
            { name: "19" },
            {
              data: [
                { id: null, lang: "79" },
                { id: 1, lang: "89" },
                { id: 0, lang: "90" },
              ],
            },
            { state: "intEducationStatus" },
          ],
        },
      ],
      errorMsg: "",
    };
  },
  unmounted() {
    clearInterval(this.updateAdsInventories);
  },
  mounted() {
    this.updateAdsInventories = setInterval(() => {
      // console.log(AdsInventories.blnCloseform);
      if (AdsInventories.blnCloseform == false) {
        this.openform(false);
        AdsInventories.blnCloseform = true;
      }
    }, 300);
  },
  //เรียกข้อมูล วิทยาเขต จากฐานข้อมูล ก่อนเปิดหน้าเว็บ
  beforeCreate() {
    this.progress = 0;
    this.timerCount = 0;
  },
  watch: {
    timerEnabled(value) {
      if (value) {
        setTimeout(() => {
          this.timerCount++;
        }, 1000);
      }
    },
    hover(value) {
      if (value == false) {
        setTimeout(() => {
          this.timerCount++;
        }, 1000);
      }
    },
    timerCountHover: {
      handler(value) {
        if (value < 2 && this.timerEnabled && this.hover == false) {
          setTimeout(() => {
            this.timerCount++;
          }, 1000);
        }
        if (value >= 2) {
          this.blnAltStatusunsucess = false;
          this.blnAltLoadingData = false;
        }
      },
      immediate: true, // This ensures the watcher is triggered upon creation
    },
    // timerCount: {
    //   handler(value) {
    //     if (value < 10 && this.timerEnabled && this.progress < 75) {
    //       setTimeout(() => {
    //         this.timerCount++;

    //         this.progress += 5 * this.timerCount;
    //       }, 200);
    //     }
    //   },

    //   immediate: true, // This ensures the watcher is triggered upon creation
    // },
  },
  created() {
    this.processLoadingData();
  },
  methods: {
    // filter ข้อมูล โดย list ข้อมูล
    filterByList(array, state, key) {
      const getNodes = (result, object) => {
        if (Array.isArray(object.children)) {
          const children = object.children.reduce(getNodes, []);

          if (children.length) {
            object.expend = true;
            result.push({ ...object, children });
            return result;
          }
        }
        if (this.filterSelected(object, state, key)) {
          object.highlight = true;
          object.expend = true;
          result.push(object);
          return result;
        }

        return result;
      };

      return array.slice().reduce(getNodes, []);
    },
    // เลือก filter
    filterSelected(item, filters, event) {
      // console.log(filters);
      return (
        item[filters]
          .toString()
          .toLowerCase()
          .indexOf(event.toString().toLowerCase()) !== -1
      );
    },
    // confirm popUp delete
    ConfirmDelete() {
      // console.log("click button delete");
      this.blnError = false;
      this.$refs.AlertPopup.blnShowMSG = false;
      this.$refs.AlertPopup.password = null;
      this.blnPopupConfirmDelete = !this.blnPopupConfirmDelete;
    },
    // skeleton loading ข้อมูลระดับปริญญา
    async processLoadingData() {
      this.progress = 0;
      try {
        await this.fetchListEduLevelList().then(() => {
          this.processData().then(() => {
            setTimeout(() => {
              // console.log("100");
              if (this.blnloadError == false) {
                this.progress = 100;
              }
            }, 1000);
          });
        });
      } catch (error) {
        console.log(error);
      }
    },
    // เรียกข้อมูลระดับปริญญาด้วย ID
    async fetchGetEduLevelListbyId(educationID) {
      this.formInactive = [];
      var resRelate = await axios.get(
        urlapi + apiedulevel.checkusedstatus + educationID
      );

      this.listRelate = { ...resRelate.data };

      this.educationID = educationID;
      await axios
        .get(urlapi + apiedulevel.getEduLevelByID + educationID)
        .then((res) => {
          // console.log(res);
          if (
            res.data.message ==
            "Not found ,Please check mapping Id in interface"
          ) {
            this.blnOpenform = false;
            this.blnnotedit = true;
            this.progress = 0;
            setTimeout(() => {
              this.blnnotedit = false;
            }, 5000);
            setTimeout(() => {
              this.processLoadingData();
            }, 2000);
          } else {
            this.formInactive = [];
            this.action = "Update";
            this.formdata = res.data.data;
            this.formdatadummy = { ...res.data.data };
            this.blnOpenform = true;

            var activeConfirm = {
              name: "intEducationStatus",
              blnConfirm: true,
            };
            this.formInactive.push(activeConfirm);
          }
          try {
            if (this.listRelate.used == true) {
              var inactiveDelete = {
                name: "blnDeleteinForm",
                inactive: true,
                tooltip: false,
              };
              this.formInactive.push(inactiveDelete);
            }
          } catch (error) {
            console.log(error);
          }
        });
    },
    //อัพเดทข้อมูลระดับปริญญา
    async fetchUpdateEduLevel(data) {
      this.msgalertDataDuplicate = [];
      if (this.$refs.header != null) {
        this.$refs.header.onClear();
      }
      let strEducationNameTH = data.strEducationNameTH;
      let strEducationNameEN = data.strEducationNameEN;
      let intEducationStatus = data.intEducationStatus;
      let formdata = {
        strEducationNameTH,
        strEducationNameEN,
        intEducationStatus,
      };
      let isEqual = JSON.stringify(this.formdatadummy) === JSON.stringify(data);
      if (isEqual == true) {
        await axios
          .get(urlapi + apiedulevel.getEduLevelByID + this.educationID)
          .then((res) => {
            if (
              res.data.message ==
              "Not found ,Please check mapping Id in interface"
            ) {
              this.blnOpenform = false;
              this.blnnotedit = true;
              setTimeout(() => {
                this.blnnotedit = false;
              }, 5000);
              this.progress = 0;
              setTimeout(() => {
                this.processLoadingData();
              }, 2000);
            } else {
              this.blnOpenform = false;
              this.blnAltStatus = true;
              this.strSMSGsuccess = "27";
              this.progress = 0;
              setTimeout(() => {
                this.processLoadingData();
                this.blnAltStatus = false;
              }, 2000);
            }
          })
          .catch(() => {
            this.blnAltStatusunsucess = true;
            setTimeout(() => {
              this.blnAltStatusunsucess = false;
            }, 5000);
          });
      }
      if (isEqual == false) {
        await axios
          .put(
            urlapi +
              apiedulevel.updateEduLevel +
              this.educationID +
              "?MemberID=" +
              AdsInventories.userinfo.intMemberID +
              "&Confirm=true",
            formdata
          )
          .then((res) => {
            // console.log(res);
            if (res.data.edited == true) {
              this.blnOpenform = false;
              this.blnAltStatus = true;
              this.strSMSGsuccess = "27";
              setTimeout(() => {
                this.blnAltStatus = false;
              }, 2000);
              this.progress = 0;
              //delay 1 sec
              setTimeout(() => {
                //ถ้าบันทึกสำเร็จ ทำการเรียกข้อมูลใหม่อีกครั้ง
                this.processLoadingData();
              }, 1000);
            }
            if (res.data.edited == false) {
              if (res.data.educationlevelnameEN == true) {
                let Message = { name: "strEducationNameEN", msg: "221" };
                this.msgalertDataDuplicate.push(Message);
              }
              if (res.data.educationlevelnameTH == true) {
                let Message = { name: "strEducationNameTH", msg: "221" };
                this.msgalertDataDuplicate.push(Message);
              }
              //ถ้าไม่สามารถ edit ได้ แล้วชื่อไม่ซ้ำ
              if (
                res.data.message ==
                "Cannot edit ,Please check mapping Id in interface"
              ) {
                // console.log("ไม่ซ้ำ");
                this.blnOpenform = false;
                this.blnnotedit = true;
                setTimeout(() => {
                  this.blnnotedit = false;
                  this.processLoadingData();
                }, 5000);
              }

              this.$refs.form.detectalertDataDuplicate(
                this.msgalertDataDuplicate
              );
            }
            if (res.data.code === 500) {
              this.blnAltStatusunsucess = true;
              this.timerCountHover = 0;
              this.timerEnabled = true;
              setTimeout(() => {
                this.blnAltStatusunsucess = false;
              }, 5000);
            }
          })
          .catch((error) => {
            // this.blnAltLoadingData = true;
            this.blnAltStatusunsucess = true;
            this.timerCountHover = 0;
            this.timerEnabled = true;
            this.hover = false;
            setTimeout(() => {
              // this.blnAltLoadingData = false;
              this.blnAltStatusunsucess = false;
            }, 5000);
            throw new Error(error);
          });
        return true;
      }
    },
    // เพิ่มข้อมูลระดับปริญญา
    async fetchInsertEducation(data) {
      this.msgalertDataDuplicate = [];
      // console.log(data);
      // var stringToJsonObject = JSON.stringify(data);
      // console.log(stringToJsonObject);
      try {
        var res = await axios.post(
          urlapi +
            apiedulevel.insertEduLevel +
            "?MemberID=" +
            AdsInventories.userinfo.intMemberID +
            "&Confirm=true",
          data
        );
        // console.log(res);
        if (res.data.inserted == true) {
          this.blnOpenform = false;
          this.blnAltStatus = true;
          this.strSMSGsuccess = "26";
          setTimeout(() => {
            this.blnAltStatus = false;
          }, 2000);
          this.progress = 0;
          //delay 1 sec
          setTimeout(() => {
            //ถ้าบันทึกสำเร็จ ทำการเรียกข้อมูลใหม่อีกครั้ง
            this.processLoadingData();
          }, 1000);
        }
        if (res.data.inserted == false) {
          if (res.data.educationlevelnameEN == true) {
            let Message = { name: "strEducationNameEN", msg: "221" };
            this.msgalertDataDuplicate.push(Message);
          }
          if (res.data.educationlevelnameTH == true) {
            let Message = { name: "strEducationNameTH", msg: "221" };
            this.msgalertDataDuplicate.push(Message);
          }

          this.$refs.form.detectalertDataDuplicate(this.msgalertDataDuplicate);
        }
        if (res.data.code === 500) {
          this.blnAltStatusunsucess = true;
          this.timerCountHover = 0;
          this.timerEnabled = true;
          setTimeout(() => {
            this.blnAltStatusunsucess = false;
          }, 5000);
        }
      } catch (error) {
        this.blnAltStatusunsucess = true;
        this.hover = false;
        this.timerEnabled = true;
        setTimeout(() => {
          this.blnAltStatusunsucess = false;
        }, 5000);
        throw new Error(error);
      }
      return true;
    },
    // reloadpage ไปหน้า logout
    reloadpage() {
      Login.logout();
      //ไปยังหน้าแรก
      // setTimeout(() => {
      //   this.$router.push("/login");
      //   this.$router.go();
      // }, 100);
    },
    //ลบข้อมูลด้วย ID
    async fetchDeleteEduLevelList(state) {
      try {
        this.blnloadConfirmDelet = true;
        if (state === false) {
          // console.log(state);
          this.blnPopupConfirmDelete = !this.blnPopupConfirmDelete;
          this.$refs.AlertPopup.password = null;
          this.blnloadConfirmDelet = false;
          // console.log(this.$refs.AlertPopup);
        } else {
          if (
            this.$refs.AlertPopup.password != null &&
            this.$refs.AlertPopup.password != ""
          ) {
            var strUsername = AdsInventories.userinfo.strUsername.split("@");
            var reslogin = await Login.login(
              strUsername[0],
              state,
              axios,
              urlapi
            );
            var login = {
              username: strUsername[0],
              password: state,
            };

            // console.log(reslogin);
            if (reslogin.logined == true) {
              this.intPinCount = reslogin.pincode;
              this.strTel = reslogin.tel;
              var res = await axios.put(
                urlapi + apiedulevel.deleteEduLevel + this.educationID,
                login
              );

              // console.log(res.data);
              if (res.data.candelete == true) {
                this.blnError = false;
                this.blnPopupConfirmDelete = false;
                this.blnAltStatus = true;
                this.strSMSGsuccess = "28";
                this.blnOpenform = false;
                this.progress = 0;
                //delay 1 sec
                setTimeout(() => {
                  if (this.$refs.header != undefined) {
                    this.$refs.header.onClear();
                  }
                  this.processLoadingData();
                  this.blnAltStatus = false;
                }, 1000);
              } else {
                // this.blnAltLoadingData = true;
                this.timerCountHover = 0;
                this.timerEnabled = true;
                this.hover = false;
                this.blnPopupConfirmDelete = false;
                // reset PIN หลังจากDelete
                this.$refs.AlertPopup.password = null;
                // setTimeout(() => {
                //   this.blnAltLoadingData = false;
                // }, 5000);

                //ถ้าลบไปแล้ว ไม่สามารถลบซ้ำได้อีก
                if (
                  res.data.message ==
                  "Cannot delete ,Please check mapping Id in interface"
                ) {
                  //ข้อมูลนี้เพิ่งถูกลบไป ไม่สามารถลบได้
                  this.blnOpenform = false;
                  this.blnnotdelete = true;
                  setTimeout(() => {
                    this.blnnotdelete = false;
                    this.processLoadingData();
                  }, 5000);
                } else {
                  //ข้อมูลนี้เพิ่งถูกใช้งาน ไม่สามารถลบได้
                  this.blnAltLoadingData = true;
                  setTimeout(() => {
                    this.blnAltLoadingData = false;
                    this.processLoadingData();
                  }, 5000);
                }
              }
            } else {
              this.intPinCount = reslogin.pincode;
              this.strTel = reslogin.tel;
              this.intWrongLogin = reslogin.WrongLogin;
              this.errorMsg = this.$t("357", {
                number: this.intPinCount - this.intWrongLogin,
              });
              this.blnError = true;
              this.$refs.AlertPopup.blnShowMSG = true;
              if (this.intWrongLogin >= this.intPinCount) {
                VueCookies.remove("UserInfo", "/", cookiesPath);
              }
            }
            this.blnloadConfirmDelet = false;
          } else {
            this.blnloadConfirmDelet = false;
            this.errorMsg = this.$t("202", { fieldname: "(PIN)" });
            this.blnError = true;
            this.$refs.AlertPopup.blnShowMSG = true;
          }
        }
      } catch (error) {
        this.$refs.AlertPopup.blnShowMSG = true;
        this.blnloadConfirmDelet = false;
        console.log(error);
      }
    },
    //จัดการข้อมูลแล้วไปแสดงผลในตาราง
    async processData() {
      try {
        // console.log(localStorage.getItem("SelectedListEducation"));
        setTimeout(() => {
          this.ListEducation.data = Enumerable.from(this.ListEducation.data)
            .orderByDescending((r) => r.intEducationStatus)
            .thenBy((r) => r.strEducationNameEN.toString().toLowerCase())
            .toArray();
        }, 1000);
        // this.progress += 10;
        setTimeout(() => {
          // if (
          //   this.isEmpty(localStorage.getItem("SelectedListEducation")) == true
          // ) {
          if (
            localStorage.getItem("SelectedListEducation") == null ||
            localStorage.getItem("SelectedListEducation") == "undefined"
          ) {
            localStorage.setItem(
              "SelectedListEducation",
              JSON.stringify(this.ListEducation.defaultField)
            );
          }

          // }
        }, 1000);
        // this.progress += 10;
        setTimeout(() => {
          if (this.$refs.table != null) {
            this.$refs.table.onClean();
          }
        }, 200);
        setTimeout(() => {
          if (this.$refs.table != null) {
            this.$refs.table.calculatePage(
              this.ListEducation.total,
              this.ListEducation.data
            );
            // ค่า config ไม่เป็น undefined
            if (localStorage.getItem("SelectedListEducation") != "undefined") {
              this.$refs.table.setSelectedList(
                JSON.parse(localStorage.getItem("SelectedListEducation"))
              );
            } else {
              // ค่า config เป็น undefined ให้ใช้ config จาก defaultfield
              // และ set ค่า config นั้นไว้
              this.$refs.table.setSelectedList(this.ListEducation.defaultField);
              localStorage.setItem(
                "SelectedListEducation",
                JSON.stringify(this.ListEducation.defaultField)
              );
            }
          }
          if (this.$refs.filter != null) {
            this.$refs.filter.Clear();
          }
        }, 1000);
      } catch (error) {
        // this.blnAltLoadingData = true;
        this.blnAltStatusunsucess = true;
        this.timerCountHover = 0;
        this.timerEnabled = true;
        this.hover = false;
        this.progress = 0;
        setTimeout(() => {
          // this.blnAltLoadingData = false;
          this.blnAltStatusunsucess = false;
        }, 5000);
        throw new Error(error);
      }
    },
    //เรียกข้อมูลระดับปริญญา
    async fetchListEduLevelList() {
      this.progress = 0;
      this.timerCount = 0;
      this.blnloadError = true;
      await axios
        .get(urlapi + apiedulevel.getEduLevelList)
        .then((res) => {
          this.progress += 10;
          this.ListDummy = res.data;
          this.ListEducation = res.data;
          this.blnloadError = false;
        })
        .catch((e) => {
          this.blnAltStatusunsucess = true;
          this.timerCountHover = 0;
          this.timerEnabled = true;
          this.hover = false;
          this.progress = 0;
          setTimeout(() => {
            this.blnAltStatusunsucess = false;
          }, 5000);
          console.log(e);
        });
    },
    //Filter รับค่า จากตัวกรองกับข้อมูลชุดเดิม
    filter(activeFilters, ListDummy) {
      this.ListEducation.data = ListDummy.slice();
      for (var field in activeFilters) {
        this.ListEducation.data = this.ListEducation.data.filter(
          (elem) => elem[activeFilters[field].state] == activeFilters[field].id
        );
      }
      if (this.$refs.table != null) {
        this.$refs.table.onClean();
        this.$refs.table.calculatePage(
          this.ListEducation.data.length,
          this.ListEducation.data
        );
      }
      this.$refs.table.SearchListDataTable(this.strSearch);
      this.blnOpenFilter = false;
      this.ListDummy.data = ListDummy;
    },
    //รับค่าจาก component filter
    filteredData(activeFilters) {
      this.activeFiltersSelected = activeFilters;

      this.filter(activeFilters, this.ListDummy.data);
    },
    // เปิด Component filter ที่กดจาก header
    openFilter(showFilter) {
      this.blnOpenFilter = showFilter;
    },
    // ค้นหาระดับปริญญา
    SearcEducation(event) {
      this.strSearch = event;
      this.$refs.table.SearchListDataTable(event, this.strSearchField);
    },
    // ตรวจสอบข้อมูลมีค่าหรือไม่
    isEmpty(value) {
      return (
        // null or undefined
        value == null ||
        // has length and it's zero
        value.length === 0 ||
        // is an Object and has no keys
        (value.constructor === Object && Object.keys(value).length === 0)
      );
    },
    // clear ข้อมูลค้นหา
    clean() {
      this.strSearch = "";
      this.SearcEducation("");
      //this.filteredData(this.activeFiltersSelected);
    },
    // เลือกข้อมูล dataType
    selectdata(event) {
      this.selectdatatype = event;
    },
    // ส่งค่าปิดฟอร์ม
    closeForm(event) {
      this.$emit("onInput", event);
    },
    //เปิดฟอร์ม
    async openform(state) {
      try {
        //ปิดฟอร์ม
        this.activeFiltersSelected = [];
        if (state == false) {
          //เคลียข้อมูลในฟอร์ม
          this.formdata = {};
          this.formdatadummy = {};
          this.ListEducation = {};
          this.ListDummy = {};
          this.progress = 0;
          await this.processLoadingData();
          if (this.$refs.header != null) {
            this.$refs.header.onClear();
          }
        }
        //เปิดฟอร์ม
        else if (state == true) {
          this.blnloading = true;
          this.listRelate = [];
          this.formInactive = [];
          //กำหนด action insert
          this.action = "Insert";
          var activeDelete = {
            name: "blnDeleteinForm",
            inactive: true,
            tooltip: false,
          };
          this.formInactive.push(activeDelete);
          //เคลียข้อมูลในฟอร์ม
          this.formdata = {};
          setTimeout(() => {
            this.blnloading = false;
          }, 600);
        }
        this.blnOpenform = state;

        // console.log(this.action);
      } catch (error) {
        console.log(error);
      }
    },
    // เก็บค่า config
    onSaveConfigDisplay(SelectedList) {
      localStorage.setItem(
        "SelectedListEducation",
        JSON.stringify(SelectedList)
      );
    },
    // load config
    reloadConfigDisplay() {
      if (this.$refs.table != null) {
        this.$refs.table.setSelectedList(
          JSON.parse(localStorage.getItem("SelectedListEducation"))
        );
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.move-to-top-level .layout:nth-child(odd) {
  order: 2;
  margin-bottom: 16px !important;
}
.skeleton {
  background-color: $black-300;
  // margin-top: 16px;
  position: relative;
  overflow: hidden;
  &::after {
    content: "";
    position: absolute;
    width: 60px;
    height: 100px;
    background-image: linear-gradient(
      to right,
      transparent,
      rgba(255, 255, 255, 0.822),
      transparent
    );
    top: 0;
    bottom: 0;
    animation: slide 1s infinite ease-out;
    transition: 0.3s;
  }
}
.skeleton-item {
  background-color: $black-300;
  // margin-top: 6px;
  position: relative;
  overflow: hidden;
  &::after {
    content: "";
    position: absolute;
    width: 60px;
    height: 100px;
    background-image: linear-gradient(
      to right,
      transparent,
      rgba(255, 255, 255, 0.822),
      transparent
    );
    top: 0;
    bottom: 0;
    transition: 0.3s;
    animation: slide 1s infinite ease-out;
  }
}
@keyframes slide {
  0% {
    transform: translateX(0);
    left: -100px;
  }
  100% {
    transform: translateX(100%);
    left: 100%;
  }
}
</style>
