const apiedulevel = {
  insertEduLevel: "/v1/api/AdsEduLevel/InsertEdulevel",
  updateEduLevel: "/v1/api/AdsEduLevel/UpdateEdulevel/",
  deleteEduLevel: "/v1/api/AdsEduLevel/DeleteEducation/",
  getEduLevelList: "/v1/api/AdsEduLevel/EduLevelList",
  getEduLevelByID: "/v1/api/AdsEduLevel/EducationByID/",
  // duplicate: "/v1/api/AdsEduLevel/EduLevelAgency",
  checkusedstatus: "/v1/api/AdsEduLevel/CheckUsageStatusEdulevel/"
};

export default apiedulevel;
