export const typeSchema = [
  {
    group: [
      {
        class: "row",
        group: [
          {
            // class: "row",
            group: [
              {
                class: "row ",
                group: [
                  {
                    class: "col-12 d-flex justify-content-between align-items-center mb-4",
                    component: [
                      {
                        name: "8",
                        label: "201", // Education level information
                        type: "HeadLabel",
                        class: "fnt-medium fnt-subtitle",
                      },
                      {
                        label: "34", // Require
                        type: "HeadLabel",
                        class: "text-danger-500 fnt-regular fnt-caption",
                      },
                    ],
                  },
                ],
              },
              {
                class: "row",

                group: [
                  {
                    class: "col-12 mb-3",
                    component: [
                      // Educational Level name TH
                      {
                        label: "106",
                        type: "InputText",
                        datavalue: "strEducationNameTH",
                        alertMsg: "202",
                        hint: "Please input in Thai",
                        name: "inpEduLevelNameTH",
                        validate: "EducationTH",
                        request:true,
                        maxlength: 200,
                      },
                    ],
                  },
                ],
              },
              {
                class: "row",

                group: [
                  {
                    class: "col-12 mb-4",
                    component: [
                      // Educational Level name
                      {
                        label: "105",
                        type: "InputText",
                        datavalue: "strEducationNameEN",
                        alertMsg: "202",
                        hint: "Please input in English",
                        name: "inpEduLevelNameEN",
                        validate: "EducationEN",
                        request:true,
                        maxlength: 200,
                      },
                    ],
                  },
                ],
              },
              {
                class: "d-flex flex-column gx-3",
                group: [
                  {
                    class: "mb-3",
                    component: [
                      {
                        datavalue: "intEducationStatus",
                        name: "19", // Status
                        type: "Toggle",
                        open: "89", // Active
                        close: "90", // Inactive
                        alertMsg: "409",
                        altMsgBox: "409",
                      },
                    ],
                  },
                ],
              },

              {
                class: "row",

                group: [
                  {
                    class:
                      "modal-footer border-0 justify-content-start p-0 mt-6 ",
                    component: [
                      {
                        type: "Button",
                        name: "36", // OK
                        value: false,
                        state: "submit",
                        class: "btn-fix me-3",
                      },
                      {
                        type: "Button",
                        name: "35", // Cancel
                        value: false,
                        state: "cancel",
                        class: "btn-fix btn-cancel",
                      },
                    ],
                  },
                ],
              },
            ],
          },
          
          {
            class: "w-100 d-flex justify-content-center",
            group: [
              {
                component: [
                  {
                    class: "mt-3",
                    type: "DeleteinForm",
                    icon: "DeleteFill",
                    classBtnIcon: "fill-secondary",
                    datavalue: "blnDeleteinForm",
                    state: "delete",
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
];
export const request = [
  "strEducationNameEN",
  "strEducationNameTH",

];
